<!--
May be relevant when the contracting API is in place.
Will need to be updated then and ff not then this can probably be removed.
-->

<template>
    <v-row>
        <v-col cols="12">
            <div>
                <h4>Carrier Appointment Requests </h4>
                <p class="body-2">Your contracting paperwork has been submitted to the carriers listed below, but you may not be contracted yet. Please check the email address you supplied during onboarding for an email from contracts@surancebay.com to resolve any 'Waiting on Agent' issues that are blocking your contract. </p>
            </div>
            <div style="width:600px; max-width:90%;">
                <v-spacer></v-spacer>
                <v-text-field v-model="search" label="Search"></v-text-field>
            </div>
            <QExpandableDataTable class="mx-n4" :search=" search" :headers="headers" :visibleHeaders="visibleHeaders" :items="filtered_appointments" item-key="id" :options.sync="options">
                <template v-slot:item.products="{ item }">
                    <v-list style="background:transparent;">
                        <v-list-item-subtitle dense class="nowrap" v-for="(product, key) in item.products" :key="key">
                            {{ product_map[product] }}
                        </v-list-item-subtitle>
                    </v-list>
                </template>
                <template v-slot:item.type="{ item }">
                    <p class="nowrap">{{ type_map[item.type] }}</p>
                </template>
                <template v-slot:item.reviewed="{ item }">
                    <p v-if="item.type === 'Contract' && item.reviewed === 'false'" style="color:#FF5252;"> Waiting on Agent</p>
                    <v-icon v-else-if="item.type === 'Contract' && item.reviewed === true" color="q_agency_1"> fas fa-check </v-icon>
                    <v-icon v-else-if="item.type === 'Hierarchy'" color="q_agency_1"> fas fa-check </v-icon>
                    <v-icon v-else-if="item.type === 'Additional State'" color="q_agency_3"> fas fa-check </v-icon>
                </template>
            </QExpandableDataTable>
        </v-col>
    </v-row>
</template>
<script>
import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'

export default {
    name: "QAgentAppointmentRequest",
    props: ['licensing'], // data passed to component from parent

    components: { QExpandableDataTable },

    data: function() { // data internal to component (not available outside)
        return {
            search: null,
            product_map: {
                'lifeFixed': "Fixed Life",
                'annuityFixed': "Fixed Annuity",
                'accidentSickness': "Accident and Sickness",
                'medSupplements': "Medical Supplements",
                'disabilityHealth': "Disability",
                'dentalVision': "Dental / Vision",
                'ltc': "Long Term Care",
            },
            type_map: {
                'Hierarchy': "Revison",
                'Contract': "Contract"
            },


            options: {
                'sortBy': ['paperworkDate'],
                'sortDesc': [true]
            }
        }
    },

    computed: {
        'headers': function() {
            return [
                { text: 'Carrier', value: 'carrierName' },
                { text: 'Paperwork Submit Date', value: 'paperworkDate' },
                { text: 'Products', value: 'products' },
                { text: 'Type', value: 'type' },
                { text: 'Reviewed', value: 'reviewed' }
            ]
        },

        visibleHeaders() {
            let hiddenHeaders = []

            if (this.$vuetify.breakpoint.mdAndDown) {
                hiddenHeaders = ['products', 'type']
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                hiddenHeaders =  ['products', 'type', 'paperworkDate']
            }

            return this.headers.filter((h) => !hiddenHeaders.includes(h.value))
        },

        'filtered_appointments': function() {
            var r = [];
            this.licensing.appointments.forEach(function(item) {
                if (item.type != 'Additional State') {
                    r.push(item)
                }
            })
            return r;
        }

    },
}
</script>
