<style scoped>
    .q-user-badges {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
</style>

<template>
    <v-container fluid class="lighest_grey my-0 px-0 py-0">
        <q-my-profile-submenu></q-my-profile-submenu>
        <!-- user info display -->
        <v-row class="mx-5 justify-left">
            <h5>
                <v-badge color="blue" :content="user.Agent.ContractLevel">
                    {{ user.name }}
                </v-badge>
                <div class="agent-title mt-2 mb-3">
                    {{ user.Agent.LeadershipLevel }} <span v-if="user.Agent.ProducerLevel">- {{ user.Agent.ProducerLevel }}</span>
                </div>
            </h5>
        </v-row>
        <v-row class="mx-5 justify-left">
            <QTabs dark>
                <v-tab @click="logTabActivty('Profile')">Profile</v-tab>
                <v-tab @click="logTabActivty('Licensing')" v-if="hasRole(['SalesRep', 'AgencyOwner', 'UnlicensedAgent'])">Licensing</v-tab>
                <v-tab v-if="false" @click="logTabActivty('Single Sign On')">Single Sign On</v-tab>
                <v-tab @click="logTabActivty('Account Grants')" v-if="hasRole(['SalesRep', 'AgencyOwner'])">Account Grants</v-tab>
                <v-tab @click="logTabActivty('Documents')" v-if="hasRole(['SalesRep', 'AgencyOwner', 'UnlicensedAgent'])">Documents</v-tab>
                <v-tab-item class="profile-info">
                    <v-row class="d-flex justify-start mb-6" cols="12" md="3" sm="12">
                        <v-col md="4" class="pa-5">
                            <div style="border: 1px solid #ccc; background-color: #9e9e9e; position: relative;">
                                <QUserBadges class="pa-5" :agent="user.Agent" />
                                <div v-if="user.avatar" style="width: 100%; max-width: 300px; margin: 0 auto">
                                    <v-img :aspect-ratio="1" :src="user.avatar"></v-img>
                                </div>
                                <div v-else class="grey" style="width: 200px; height: 200px; padding-top: 27px; display: flex; align-content: center; justify-content: center; margin: 0 auto">
                                    <img alt="Generic User" contain src="@/assets/images/generic_user.png" />
                                </div>
                            </div>
                            <div>
                                <v-btn class="ma-2" tile large outlined color="q_green_1" @click="upload_dialog = true">
                                    <v-icon left>fas fa-camera</v-icon> Adds / Update Photo
                                </v-btn>
                            </div>
                            <!-- <v-divider></v-divider>
                            <div>
                                <v-alert class="ma-2" type="warning" v-if="invalidURL"> This does not appear to be a valid URL.<br />This may cause problems when redirecting to this link. </v-alert>
                                <v-text-field label="Calendar URL" v-model="appointment_url"></v-text-field>
                                <v-btn class="ma-2" tile large outlined color="q_green_1" @click="updateConfig">
                                    <v-icon left>fas fa-calendar</v-icon> Add/Update Calendar URL
                                </v-btn>
                                <v-progress-linear class="mb-3" indeterminate v-if="loading_config"></v-progress-linear>
                            </div> -->
                            <v-list v-if="hasAgentFlags">
                                <v-divider></v-divider>
                                <v-list-item v-if="user.Agent.LastLoginDate || user.Agent.LastActivityDate">
                                    <v-list-item-icon>
                                        <v-icon color="q_green_1" style="margin: 0 auto">fas fa-calendar-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="user.Agent.LastLoginDate" class="">
                                            <div><b>Last HQ Login:</b></div>
                                            <div>{{ formatDateCalendar(user.Agent.LastLoginDate) }}</div>
                                        </v-list-item-title>
                                        <v-list-item-title v-if="user.Agent.LastActivityDate" class="pt-2">
                                            <div><b>Last Opt! Login:</b></div>
                                            <div>{{ formatDateCalendar(user.Agent.LastActivityDate) }}</div>
                                        </v-list-item-title>
                                        <v-list-item-title v-if="user.Agent.FastTrack.lightSpeedFastTrackCompleteDate">
                                            <div style="display: inline-block; width: 150px"><b>FastTrack Completion Date:</b></div>
                                            <div style="display: inline-block; width: 150px">{{ formatDateCalendarWithYear(user.Agent.FastTrack.lightSpeedFastTrackCompleteDate) }}</div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col md="4" class="pa-5" align="left">
                            <v-alert type="warning" v-if="user.Agent.Flanking.length > 0 && user.Agent.Flanking[0].HasSherpaInd == 0" border="top" colored-border elevation="2">
                                <div class="overline mb-4">You are being flanked by</div>
                                <div v-for="flanking in user.Agent.Flanking" :key="flanking.AgentID" @click="clickAgent(flanking.AgentCode)" style="cursor:pointer;">
                                    {{flanking.AgentName}}
                                    <span v-if="flanking.FlankExpDate != null">
                                        - {{formatReadableDate(flanking.FlankExpDate)}} ({{relativeDate(flanking.FlankExpDate)}})
                                    </span>
                                </div>
                            </v-alert>

                            <v-list>
                                <v-list-item>
                                    <v-list-item-icon class="text-center" style="width: 30px">
                                        <v-icon color="q_green_1" style="margin: 0 auto">fas fa-envelope</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.email }}<br />
                                            <span v-if="user.Agent.BusinessEmail">
                                                Business : {{ user.Agent.BusinessEmail }}
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="#999999" dark v-bind="attrs" v-on="on"> fas fa-question-circle </v-icon>
                                                    </template>
                                                    <span>Business email is for DTC use only.</span>
                                                </v-tooltip>
                                            </span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-icon class="text-center" style="width: 30px">
                                        <v-icon color="q_green_1" style="margin: 0 auto">fas fa-phone</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <a :href="'tel:' + user.Agent.AgentPhone">{{ user.Agent.AgentPhone }}</a><br />
                                            <span v-if="user.Agent.BusinessPhone">
                                                Business : <a style="padding-right: 4px" :href="'tel:' + user.Agent.BusinessPhone">{{ user.Agent.BusinessPhone }}</a>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="#999999" dark v-bind="attrs" v-on="on"> fas fa-question-circle </v-icon>
                                                    </template>
                                                    <span>Business phone is for DTC use only.</span>
                                                </v-tooltip>
                                                <br />
                                            </span>
                                            <span v-if="user.Agent.CellPhone">Cell : <a :href="'tel:' + user.Agent.CellPhone">{{ user.Agent.CellPhone }}</a></span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-icon class="text-center" style="width: 30px">
                                        <v-icon color="q_green_1" style="margin: 0 auto">fas fa-address-card</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.Agent.StreetAddress }}<br />
                                            {{ user.Agent.City }}, {{ user.Agent.State }} {{ user.Agent.Zip }}<br />
                                            <span v-if="user.Agent.County">County : {{ user.Agent.County }}</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-icon class="text-center" style="width: 30px">
                                        <v-icon style="margin: 0 auto" color="q_green_1">fas fa-user-secret</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="overline">Agent Code:</div>
                                            <div>{{ user.AgentCode }}</div>
                                            <div class="overline" style="padding-top: 1em">Opt ID:</div>
                                            <div>{{ user.OptID }}</div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-icon class="text-center" style="width: 30px">
                                        <v-icon style="margin: 0 auto" color="q_green_1">fas fa-calendar-alt</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="overline">Since</div>
                                            <div>{{ user.Agent.ContractStartDt }}</div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <!--
                                <template v-if="isFullTimeAgent !== null">
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width: 30px">
                                            <v-icon style="margin: 0 auto" color="q_green_1">fas fa-user</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="d-flex align-center">
                                                <span class="mr-4">{{ isFullTimeAgent ? 'Full Time Agent' : 'Part Time Agent' }}</span>
                                                <v-btn outlined color=" q_green_1" icon @click="surveyDialog = true" style="border-radius:50%;">
                                                    <v-icon>icon-edit-q</v-icon>
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </template>
                                -->
                                <v-list-item>
                                    <v-list-item-icon class="text-center" style="width: 30px">
                                        <v-icon style="margin: 0 auto" color="q_green_1">fas fa-crown</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="overline text-wrap" style="display:inline-block:">Leadership Level:</div>
                                            <div style="display:inline-block:">
                                                {{ user.Agent.LeadershipLevel }}<span v-if="user.Agent.ProducerLevel">, {{ user.Agent.ProducerLevel }}</span>
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-icon class="text-center" style="width: 30px">
                                        <v-icon style="margin: 0 auto" color="q_green_1">fas fa-file-contract</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="overline text-wrap" style="display:inline-block:">Contract Level:</div>
                                            <div style="display:inline-block:">{{ user.Agent.ContractLevel }}</div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item v-if="hasAgentFlags">
                                    <v-list-item-icon>
                                        <v-icon color="q_green_1" style="margin: 0 auto">fas fa-users</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="overline">Memberships and subscriptions</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-list dense>
                                                <v-list-item v-if="user.Agent.IsAdvisoryBoardMember" class="body-2">
                                                    <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                    <v-list-item-content class="pl-3"> Advisory Board Member </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="user.Agent.IsOwnerCircleMember" class="body-2">
                                                    <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                    <v-list-item-content class="pl-3">Owner's Circle Member</v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="user.Agent.HasManagerAccess" class="body-2">
                                                    <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                    <v-list-item-content class="pl-3">Virtual Assistant</v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="user.Agent.PCSParticipant" class="body-2 pt-2">
                                                    <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                    <v-list-item-content class="pl-3">PCS Participant <br />
                                                        Start Date: {{ formatDate(user.Agent.PCSStartDate) }}</v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="this.hasRole(['Staff', 'SuperAdmin', 'Exec']) && user.Agent.SeasonedNewAgentInd" class="body-2 pt-2">
                                                    <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                    <v-list-item-content class="pl-3">Seasoned New Agent</v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="user.Agent.QuilityRXGroupNumber" class="body-2 pt-2">
                                                    <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                    <v-list-item-content class="pl-3">QuilityRx Agent <br />
                                                        GroupCode: {{ user.Agent.QuilityRXGroupNumber }}<br />
                                                        Start Date: {{ formatDate(user.Agent.QuilityRXGroupDate) }}</v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="user.Agent.CurrentSummitStep" class="body-2 pt-2">
                                                    <v-icon color="q_green_1">far fa-check-circle</v-icon>
                                                    <v-list-item-content class="pl-3">
                                                        Summit <br />
                                                        Current Summit Step: {{ ordinalSummitStep }} - {{ user.Agent.CurrentSummitStep.StepTitle }}
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-else>
                                    <v-list-item-content>
                                        <v-list-item-title class="overline">Nothing to see here yet.</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>
                        </v-col>
                        <v-card v-if="hasRole(['SalesRep', 'AgencyOwner'])" tile flat class="upline pa-5">
                            <q-agent-upline :agent="user.Agent"></q-agent-upline>
                        </v-card>
                    </v-row>
                    <!-- <v-row class="mx-5 pb-4 justify-left" style="border-top: 1px #ccc solid">
                        <v-btn text color="primary" @click="clearPreferences" :disabled="configLoading">Reset Preferences <v-progress-circular indeterminate size="20" v-if="configLoading"></v-progress-circular>
                        </v-btn>
                    </v-row> -->
                </v-tab-item>
                <v-tab-item v-if="hasRole(['SalesRep', 'AgencyOwner', 'UnlicensedAgent'])" class="licensing-info">
                    <div class="pa-4">
                        <h2>Licensing</h2>
                        <q-agent-licensing-sure-lc :agent="user.Agent"></q-agent-licensing-sure-lc>
                    </div>
                </v-tab-item>
                <v-tab-item v-if="false" class="sso pa-4">
                    <v-alert v-if="isImpersonating" type="warning">You are currently impersonating an Agent. You cannot perform any linking of account while impersonating.</v-alert>
                    <auth-link-accounts></auth-link-accounts>
                </v-tab-item>
                <v-tab-item class="account grants pa-4" v-if="hasRole(['SalesRep', 'AgencyOwner'])">
                    <q-account-grants v-if="hasRole(['SalesRep', 'AgencyOwner'])" :agent="user"></q-account-grants>
                    <q-impersonate-account v-if="hasRole(['SalesRep', 'AgencyOwner'])" :agent="user"></q-impersonate-account>
                </v-tab-item>
                <v-tab-item class="Documents pa-4" v-if="hasRole(['SalesRep', 'AgencyOwner', 'UnlicensedAgent'])">
                    <q-agent-documents :agent="user.Agent"></q-agent-documents>
                </v-tab-item>
            </QTabs>
            <!-- /user info display -->
            <!-- account connection buttons -->
        </v-row>
        <!-- /account connection buttons -->
        <!-- User Object Params -->
        <v-row wrap class="mx-5 justify-left" v-if="hasRole('SuperAdmin')">
            <v-col cols="12" class="my-5 pa-5" style="background-color: white">
                <div>
                    <div class="q-title">{{ $auth.auth_user.name }} - {{ role }}</div>
                    <h4>User Object Params</h4>
                </div>
                <v-col v-for="(value, key) in user" :key="key" cols="12" sm="2">
                    <strong>{{ key }}</strong> : {{ value }}
                </v-col>
            </v-col>
        </v-row>
        <!-- photo upload modal window -->
        <v-dialog v-model="upload_dialog" width="500">
            <v-card class="pa-6 text-center" height="100%">
                <div>
                    <p>Profile photos should be square and no smaller than 200px x 200px. Images will be cropped to fit.</p>
                    <vue-dropzone ref="myVueDropzone" v-on:vdropzone-success="uploadComplete" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="surveyDialog" width="500">
            <v-card :disabled="loading_config" class="pa-6">
                <FormWithStepsRenderer slug="full-time-survey" @saved="updateTypeOfAgent()" />
                <v-progress-linear v-if="loading_config" class="mb-3" indeterminate></v-progress-linear>
            </v-card>
        </v-dialog>
        <!-- /photo upload modal window -->
    </v-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import QMyProfileSubmenu from "@/components/navigation/Submenus/QMyProfileSubmenu.vue";
import AuthLinkAccounts from "@/auth/LinkAccounts.vue";
import User from "@/store/Models/User";
import { getInstance } from "@/auth/index";
import PromotionLevels from "@/store/PromotionLevels.json";
import QAccountGrants from "@/components/utils/QAccountGrants.vue";
import QImpersonateAccount from "@/components/utils/QImpersonateAccount.vue";
import QuilityAPI from "@/store/API/QuilityAPI.js";
import QAgentUpline from "@/components/lists/QAgentUpline.vue";
import QAgentDocuments from "@/components/agent_admin/QAgentDocuments.vue";
import QTabs from "../../components/navigation/QTabs.vue";
import QUserBadges from "@/components/profile/QUserBadges.vue";
import QAgentLicensingSureLc from "@/components/utils/QAgentLicensingSureLc.vue";
import Agent from '@/store/Models/Agent'
import { FormWithStepsRenderer } from '../../modules/FML'

export default {
    name: "Profile",
    data() {
        return {
            agentcode: null,
            agentdownline: {},
            current_agent: null,
            stickyTop: 0,
            date_period: "monthly",
            editUpline: false,
            note_count: 0,
            dropzoneOptions: {
                url: "/api/my/avatar",
                headers: {},
                addRemoveLinks: true,
                acceptedFiles: "image/*",
                paramName: "image",
                thumbnailWidth: 100,
                thumbnailHeight: 100,
            },
            configLoading: false,
            upload_dialog: false,
            appointment_url: null,
            appointment_config_data: null,
            loading_config: false,
            surveyDialog: false,
            isFullTimeAgent: null,
        };
    },
    mounted: function() {
        var g = this;
        // this.loadConfigs();
        this.config().then(function(token) {
            g.token = token;
            g.dropzoneOptions.headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
                //'X-Requested-With': 'XMLHttpRequest',
            };
        });
        this.reset();
    },
    activated() {
        this.initTypeOfAgent()
    },
    computed: {
        agent: {
            get() {
                return this.$store.state.entities.agent.currentAgent;
            },
            set(v) {
                Agent.commit((state) => {
                    state.loading = false
                    state.currentAgent = v
                })
            }
        },
        loading: function() {
            return this.$store.state.entities.agent.loading;
        },
        promotionLevel: function() {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (this.user_meta.Role == PromotionLevels[i].Role) {
                    return PromotionLevels[i];
                }
            }
            return {};
        },
        hasAgentFlags: function() {
            return this.user.Agent.LastLoginDate || this.user.Agent.LastActivityDate || this.user.Agent.IsAdvisoryBoardMember || this.user.Agent.IsOwnerCircleMember || this.user.Agent.HasManagerAccess || this.user.Agent.PCSParticipant;
        },
        ordinalSummitStep: function() {
            const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" });
            const suffixes = {
                one: "st",
                two: "nd",
                few: "rd",
                other: "th",
            };
            const currentStep = parseInt(this.user.Agent.CurrentSummitStep.StepOrder) + 1;
            const suffix = suffixes[english_ordinal_rules.select(currentStep)];

            return `${currentStep}${suffix}`;
        },
        invalidURL: function() {
            return this.appointment_url ? !this.validURL(this.appointment_url) : false;
        },
    },
    methods: {
        uploadComplete: function(file) {
            var resp = JSON.parse(file.xhr.response);
            var user = JSON.parse(JSON.stringify(this.user));
            user.avatar = resp.file;
            User.create({ data: user });
            this.upload_dialog = false;
        },
        showAgent(agent) {
            this.current_agent = agent;
        },
        reset: function() {
            this.agentcode = this.$route.params.agentcode;
            this.note_count = 0;
            QuilityAPI.getAgent(this.agentcode, ["LeadershipLevel"]);
        },
        getDownline: function() {
            return this.recursiveDownline(this.agent.Downline);
        },
        getAgentDownline: function(AgentCode) {
            var g = this;
            QuilityAPI.getAgentDownline(AgentCode, [], function(json) {
                g.agentdownline[AgentCode] = json;
            });
        },
        recursiveDownline: function(children) {
            var str = "";
            for (var i = 0; i < children.length; i++) {
                str = str + "<a href='/admin/agents/" + children[i].AgentCode + "'>" + children[i].AgentName + "</a><br>";
                if (children[i].Downline == null) continue;
                if (children[i].Downline.length > 0) {
                    str = str + this.recursiveDownline(children[i].Downline);
                }
            }
            return str;
        },
        loadAgent(agentcode) {
            this.$router.push("/admin/agents/" + agentcode);
        },
        updateNoteCount(notes) {
            this.note_count = notes.meta.note_count;
        },
        config: function() {
            return new Promise((resolve, reject) => {
                getInstance()
                    .getTokenSilently()
                    .then(function(token) {
                        resolve(token);
                    });
            });
        },
        clearPreferences: function() {
            this.configLoading = true;
            var g = this;
            QuilityAPI.clearConfig().then(function(json) {
                g.configLoading = false;
            });
        },
        clickAgent: function(ac) {
            this.$router.push("/agents/" + ac);
        },
        // loadConfigs: async function() {
        //     this.loading_config = true;
        //     try {
        //         const config_data = await QuilityAPI.getAgentConfigsAppointmentCalendar(this.user.AgentCode);
        //         this.appointment_config_data = config_data.data.AgentConfig.find((config) => config.ConfigName == "AppointmentCalendarURL");

        //         if (typeof this.appointment_config_data !== "undefined" && this.appointment_config_data != null) {
        //             this.appointment_url = this.appointment_config_data.ConfigValue;
        //         }
        //         this.loading_config = false;
        //     } catch (error) {
        //         console.log(error);
        //         this.showError("There was an issue with loading your appointment url.");
        //         this.loading_config = false;
        //     }
        // },
        // updateConfig: async function() {
        //     this.loading_config = true;
        //     let config_data;

        //     try {
        //         if (typeof this.appointment_config_data != "undefined" && this.appointment_config_data.hasOwnProperty("ID")) {
        //             // update
        //             this.appointment_config_data.ConfigValue = this.appointment_url;
        //             config_data = await QuilityAPI.updateAgentConfigAppointmentCalendar(this.user.AgentCode, this.appointment_config_data);
        //         } else {
        //             // create
        //             let config_info = {
        //                 ConfigName: "AppointmentCalendarURL",
        //                 ConfigValue: this.appointment_url,
        //                 DataType: "string",
        //             };
        //             config_data = await QuilityAPI.addAgentConfigAppointmentCalendar(this.user.AgentCode, config_info, "Agent Config");
        //         }

        //         this.appointment_config_data = config_data.data.AgentConfig.find((config) => config.ConfigName == "AppointmentCalendarURL");

        //         if (typeof this.appointment_config_data !== "undefined" && this.appointment_config_data != null) {
        //             this.appointment_url = this.appointment_config_data.ConfigValue;
        //         }
        //         this.loading_config = false;
        //     } catch (error) {
        //         console.log(error);
        //         this.showError("There was an issue with updating your appointment url.");
        //         this.loading_config = false;
        //     }
        // },
        validURL: function(url) {
            var pattern = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
                "i"
            ); // fragment locator
            return !!pattern.test(url);
        },
        test() {
            alert("hello world");
        },
        initTypeOfAgent() {
            return; //temporarily comment out
            if (this.user?.config?.Fulltime?.ConfigValue === 'true') {
                this.isFullTimeAgent = true
            } else if (this.user?.config?.Fulltime?.ConfigValue === 'false') {
                this.isFullTimeAgent = false
            } else {
                this.isFullTimeAgent = null
            }

            this.surveyDialog = this.isFullTimeAgent === null
        },
        async updateTypeOfAgent() {
            this.loading_config = true
            await QuilityAPI.getDataInit()
            this.initTypeOfAgent()
            this.loading_config = false
        },
    },

    watch: {
        "$route.params.agentcode": function(newV, oldV) {
            if (typeof newV != "undefined" && this.agentcode != newV) {
                this.reset();
            }
        },
    },
    components: {
        QMyProfileSubmenu,
        AuthLinkAccounts,
        vueDropzone: vue2Dropzone,
        QAccountGrants,
        QImpersonateAccount,
        QAgentUpline,
        QAgentDocuments,
        QTabs,
        QUserBadges,
        QAgentLicensingSureLc,
		FormWithStepsRenderer
    },
};
</script>
